<!-- NFT -->
<template>
     <div>
       <van-cell v-for="(item,index) in list"
                 :key="index">
         <div class="report-list-box">
           <div class="report-box-left">
             <div class="report-left-title">
               <!-- <div>CICO#0001</div> -->
               <div>{{ item.after_level }}</div>
               <!-- <div style="margin-bottom:14px;" v-if="item.type == 0">{{ item.type_name }} - {{$t('成长')}}</div> -->
               <div style="margin-bottom:14px;" :class="{'vn-italic': $i18n.locale == 'vn'}">{{ item.name }}</div>
             </div>
             <div class="report-left-text">
               <div class="report-text-1" :class="{'vn-italic': $i18n.locale == 'vn'}">{{$t('等级')}}{{ item.grade }}</div>
               <div class="report-text-1">TOKEN-ID : {{ item.token_id }}</div>
               <!-- <div class="report-text-1">{{$t('成本')}} : -{{ item.money }} {{ item.money_type }}</div> -->
               <div class="report-text-1" :class="{'vn-italic': $i18n.locale == 'vn'}">{{$t('成本')}} : -{{ item.money }} </div>
               <!-- <div class="report-text-1" style="margin-top:14px;" v-if="item.type == 0">CICO#0001 > CORNN#0005</div> -->
               <div class="report-text-1" style="margin-top:14px;" v-if="item.type == 0">{{ item.before_level }} > {{ item.after_level }}</div>
             </div>
           </div>
           <div class="report-box-right">{{ item.created }}</div>
         </div>
       </van-cell>
     </div>
   </template>
   <script>
   export default {
     props: {
       list: {
         type: Array,
         default: function () {
           return []
         }
       },
     },
     data () {
       return {
         
       }
     },
   
     methods: {
       
     },
   
   }
   </script>
   <style lang="less" scoped>
   .report-myreport {
     width: 92%;
     margin: 18px auto 5px auto;
     .report-myreport-title {
       font-family: REEJI-PinboGB-Flash;
       font-size: 24px;
       font-weight: normal;
       font-stretch: normal;
       letter-spacing: 0px;
       color: #ffffff;
     }
     .report-myreport-list {
       height: 45vh;
       background-color: #ffffff;
       border-radius: 5px;
       overflow: auto;
       padding: 0 10px;
   
       
       .report-list-box {
         display: flex;
   
         .report-box-left {
           flex: 1;
   
           .report-left-title {
             font-family: AeroMaticsDisplayItalic;
             font-size: 14px;
             font-weight: normal;
             font-stretch: normal;
             letter-spacing: 0px;
             color: #2b2b2b;
             font-weight: 600;
           }
           .report-left-text {
          //    margin-top: 10px;
             .report-text-1 {
               font-family: AeroMaticsDisplayItalic;
               font-size: 12px;
               font-weight: normal;
               letter-spacing: 0px;
               color: #2b2b2b;
             }
             
           }
         }
         .report-box-right {
           flex: 1;
           text-align: center;
           font-family: AeroMaticsDisplayItalic;
           font-size: 14px;
           font-weight: normal;
           font-stretch: normal;
           letter-spacing: 0px;
           color: #2b2b2b;
           font-weight: 600;
         }
       }
   
       /deep/ .van-list {
         height: 100%;
       }
       /deep/ .van-cell {
         padding: 13px 4px;
         line-height: 1;
       }
       /deep/ .van-cell:nth-child(1) {
         padding-top: 8px;
       }
   
       /deep/ .van-cell::after {
         border: 0;
       }
     }
     .report-myreport-list::-webkit-scrollbar {
       width: 5px;
       border-radius: 5px;
       background: #fff;
     }
     .report-myreport-list::-webkit-scrollbar-track {
       border-radius: 5px;
       background: #fff;
     }
     .report-myreport-list::-webkit-scrollbar-thumb {
       border-radius: 5px;
       background: #5c65cc;
     }
   }
   </style>