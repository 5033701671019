<template>
  <div class="market">
    <watch-head :title="$t('报告')"
                :img="require('@/assets/bracelet_img/report/report.png')"></watch-head>

    <div class="report-tabs">
      <div class="report-tabs-list"
           :class="{ 'report-tabs-currentList':tabsCurrent == index, 'vn-bold-italic': $i18n.locale == 'vn'}"
           v-for="(list, index) in reportTabs"
           :key="index"
           @click="tabsToggle(list, index)">{{list.tabs}}</div>
    </div>
    <div class="report-filter">
      <!-- <div class="report-filter-search">
        <div class="report-search-ipt"><input :value="searchValue"
                 type="text"></div>
        <div class="report-search-img"><img src="@/assets/bracelet_img/55.png"></div>
      </div> -->
      <div class="report-filter-time">
        <div @click="timeConfirm"
             class="report-time-img"><img src="@/assets/bracelet_img/report/filter.png"> </div>
        <div class="report-time-choose">
          <div class="report-choose-start">
            <!-- <van-cell :value="startDate"
                      @click="startTimeShow = true" /> -->
            <van-field v-model="startDate"
                       :class="{'vn-italic': $i18n.locale == 'vn'}"
                       label-width="0"
                       readonly
                       :placeholder="$t('开始时间')" />
            <van-calendar style="color:#2b2b2b;"
                          :class="{'vn-italic': $i18n.locale == 'vn'}"
                          color="#6480f3"
                          :min-date="minDate"
                          v-model="startTimeShow"
                          @confirm="onStartTime"
                          :confirm-text="$t('确定')"
                          :title="$t('日期选择')"
                          :show-subtitle="false" />
            <img src="@/assets/bracelet_img/44.png"
                 @click="startTimeShow = true">
          </div>
          <div class="report-choose-zhi">{{$t('至')}}</div>
          <div class="report-choose-start">
            <!-- <van-cell :value="endDate"
                      @click="endTimeShow = true" /> -->
            <van-field v-model="endDate"
                       :class="{'vn-italic': $i18n.locale == 'vn'}"
                       label-width="0"
                       readonly
                       :placeholder="$t('结束时间')" />
            <van-calendar style="color:#2b2b2b;"
                          color="#6480f3"
                          :class="{'vn-italic': $i18n.locale == 'vn'}"
                          :min-date="minDate"
                          v-model="endTimeShow"
                          :confirm-text="$t('确定')"
                          :title="$t('日期选择')"
                          :show-subtitle="false"
                          @confirm="onEndTime" />
            <img src="@/assets/bracelet_img/44.png"
                 @click="endTimeShow = true">
          </div>
        </div>
      </div>
      <div class="report-myreport">
        <div class="report-myreport-title"
             :class="{'vn-bold-italic': $i18n.locale == 'vn'}">{{title}}</div>
        <!-- 运动收益 -->
        <div v-show="tabsCurrent === 0"
             class="profit">
          <span :class="{'vn-italic': $i18n.locale == 'vn'}">{{$t('运动收益')}}：{{ parseFloat(sportMoney.toFixed(6)) }} IONZ</span>
          <van-button class="btn"
                      type="default"
                      :class="{'vn-italic': $i18n.locale == 'vn'}"
                      :loading="sportsLoading"
                      @click="receiveIncome(0)">{{$t('领取')}}</van-button>
        </div>
        <!-- 社交收益 -->
        <div v-show="tabsCurrent === 0"
             class="profit">
          <span :class="{'vn-italic': $i18n.locale == 'vn'}">{{$t('社交收益')}}：{{ parseFloat(chatMoney.toFixed(6)) }} IONZ</span>
          <van-button class="btn"
                      type="default"
                      :class="{'vn-italic': $i18n.locale == 'vn'}"
                      :loading="socialityLoading"
                      @click="receiveIncome(1)">{{$t('领取')}}</van-button>
        </div>
        <!-- 领取团队收益 -->
        <div v-show="tabsCurrent === 1"
             class="profit">
          <span :class="{'vn-italic': $i18n.locale == 'vn'}">{{$t('团队收益')}}：{{ userInfo.money }} SOLARIX</span>
          <van-button class="btn"
                      type="default"
                      :class="{'vn-italic': $i18n.locale == 'vn'}"
                      :loading="teamLoading"
                      @click="receive()">{{$t('领取')}}</van-button>
        </div>
        <!-- 团队NFT升级奖励 -->
        <div v-show="tabsCurrent === 1"
             class="profit">
          <span :class="{'vn-italic': $i18n.locale == 'vn'}">{{$t('团队NFT升级奖励')}}：{{ gradeTeamAward }} BITNEY</span>
          <van-button class="btn"
                      type="default"
                      :class="{'vn-italic': $i18n.locale == 'vn'}"
                      :loading="gradeTeamAwardLoading"
                      @click="teamGradeAward()">{{$t('领取')}}</van-button>
        </div>
        <!-- 团队NFT升级奖励 -->
        <!-- <div v-show="tabsCurrent === 1"
             class="profit">
          <span :class="{'vn-italic': $i18n.locale == 'vn'}">{{$t('团队NFT升级奖励')}}：{{ userInfo.money }} SOLARIX</span>
          <van-button class="btn"
                      type="default"
                      :class="{'vn-italic': $i18n.locale == 'vn'}"
                      :loading="teamLoading"
                      @click="receive()">{{$t('领取')}}</van-button>
        </div> -->
        <!-- 领取升级的nft -->
        <div v-show="tabsCurrent === 2"
             class="profit">
          <span :class="{'vn-italic': $i18n.locale == 'vn'}">{{$t('已升级的nft数量')}}：{{ upgradeNftList.length }}</span>
          <van-button class="btn"
                      type="default"
                      :class="{'vn-italic': $i18n.locale == 'vn'}"
                      :loading="receiveNftLoading"
                      @click="drawNft()">{{$t('领取')}}</van-button>
        </div>
        <div class="report-myreport-list">
          <van-list v-model="loading"
                    :class="{'vn-italic': $i18n.locale == 'vn'}"
                    :finished="finished"
                    :immediate-check="false"
                    :offset="20"
                    :finished-text="$t('没有更多了')"
                    @load="onLoad">
            <!-- <van-cell v-for="(item,index) in list"
                         :class="index + 1 < list.length ? 'report-list-border':''"
                         :key="item">
                 <div class="report-list-box">
                   <div class="report-box-left">
                     <div class="report-left-title">
                       <div>+ 200 SOLARIX</div>
                       <div>"运动"</div>
                     </div>
                     <div class="report-left-text">
                       <div class="report-left-title">总分:120.000</div>
                       <div class="report-text-1">持续 - 00:30:00</div>
                       <div class="report-text-1">玩家 - 08 / 08</div>
                       <div class="report-text-1">排行 - 4 / 5（-20 分数）</div>
                     </div>
                   </div>
                   <div class="report-box-right">28 / 08 / 2022</div>
                 </div>
               </van-cell> -->
            <earnings v-if="tabsCurrent === 0"
                      :list="list"></earnings>
            <rewards v-if="tabsCurrent === 1"
                     :list="list"></rewards>
            <!-- <task v-if="tabsCurrent === 2"
                  :list="list"></task> -->
            <nft v-if="tabsCurrent === 2"
                 :list="list"></nft>
          </van-list>
        </div>
      </div>
    </div>
    <watch-foot></watch-foot>
  </div>
</template>
   <script>
import watchHead from '@/components/watchHead'
import watchFoot from '@/components/watchFoot/index.vue'
import earnings from './components/earnings'
import rewards from './components/rewards'
import task from './components/task'
import nft from './components/nft'
import { getUpgradeList, sendApi, moneyTotalApi, receiveNft, userParent, getIsMarket, MarketplaceApi, userInfo, receiveApi, NFTUpgradeTeamAwardReceiveTotal, NFTUpgradeTeamAwardReceive } from '@/api/api'
import { mapState } from 'vuex'
export default {
  data () {
    return {
      upgradeNftList: [],
      receiveNftLoading: false,
      chatMoney: 0,
      sportMoney: 0,
      sportsLoading: false,
      socialityLoading: false,
      reportTabs: [
        { tabs: this.$t('收益'), name: this.$t('我的收益报告') + ' ：' },
        { tabs: this.$t('奖励'), name: this.$t('我的奖励报告') + ' ：' },
        // { tabs: this.$t('任务'), name: this.$t('我的任务报告') + ' ：' },
        { tabs: 'NFT', name: this.$t('我的NFT报告') + ' ：' },
      ],
      searchValue: '',
      tabsCurrent: 0,
      title: this.$t('我的收益报告') + ' ：',
      startDate: '',
      startTime: null,
      endDate: '',
      endTime: null,
      startTimeShow: false,
      endTimeShow: false,
      minDate: new Date(2010, 0, 1),
      list: [],
      loading: false,
      finished: false,
      page: 1,
      limit: 10,
      timer: null,
      userInfo: {},
      teamLoading: false,
      gradeTeamAward: 0,
      gradeTeamAwardLoading: false,
    }
  },
  components: {
    watchHead,
    watchFoot,
    earnings,
    rewards,
    task,
    nft,
  },
  computed: {
    ...mapState([
      'web3', 'marketContract'
    ]),
  },
  created () {
    this.waitForGlobal()
  },
  methods: {
    //团队NFT升级奖励领取
    teamGradeAward(){
      this.gradeTeamAwardLoading = true
      if (this.gradeTeamAward == 0) {
        this.$toast('暂无收益可领取'); //暂无收益可领取
        this.gradeTeamAwardLoading = false
        return
      }
      NFTUpgradeTeamAwardReceive().then(res => {
        this.gradeTeamAwardLoading = false
        this.getTeamAward()
        this.$toast(res.msg); //领取成功
      })
    },
    //团队NFT升级奖励领取总数
    getTeamAward () {
      NFTUpgradeTeamAwardReceiveTotal().then(res => {
        if (res.code == 0) {
          this.gradeTeamAward = res.data.count
        }
      })
    },
    /* 用户信息 */
    getUserInfo () {
      userInfo({}, localStorage.getItem('Token')).then(res => {
        if (res.code == 0) {
          window.localStorage.setItem('userInfo', JSON.stringify(res.data))
          this.userInfo = res.data
        }
      })
    },
    /* 领取团队收益 */
    receive () {
      this.teamLoading = true
      if (this.userInfo.money == 0) {
        this.$toast('暂无团队收益可领取'); //暂无团队收益可领取
        this.teamLoading = false
        return
      }
      receiveApi().then(res => {
        this.teamLoading = false
        this.getUserInfo()
        this.$toast(res.msg); //领取成功
      })
    },
    //已升级的nft
    getUpgradeNft () {
      receiveNft().then(res => {
        if (res.code == 0) {
          MarketplaceApi({ type: 3 }).then(resu => {
            if (resu.code == 0) {
              this.upgradeNftList = resu.data.data
              console.log(this.upgradeNftList)
            }
          })
        }else{
          this.upgradeNftList = []
        }
      })

    },
    //领取升级的nft
    drawNft () {
      if (this.upgradeNftList.length <= 0) {
        this.$toast('暂无nft可领取'); //暂无nft可领取
        return
      }
      this.receiveNftLoading = true
      userParent().then(res => {
        if (res.code == 0) {
          receiveNft().then(resu => {
            if (resu.code == 0) {
              let toast = this.$toast.loading({
                duration: 0, // 持续展示 toast
                forbidClick: true,
                message: this.$t('领取中...'), //领取中...
              });
              this.marketContract.methods.buy(this.upgradeNftList[0].online_order_id).send({ from: localStorage.getItem('defaultAddress') }).then((res) => {
                toast.clear();
                this.receiveNftLoading = false
                this.$toast(this.$t('领取成功')); //领取成功
                this.getUpgradeNft();
              }).catch(err => {
                toast.clear();
                this.receiveNftLoading = false
                this.$toast(this.$t('领取失败')); //领取失败
              })
            } else {
              this.receiveNftLoading = false
              this.$toast(resu.msg);
            }
          })
        } else {
          this.receiveNftLoading = false
          this.$toast(res.msg);
        }
      })
    },
    //查询可领取收益数量
    getMoneyTotal () {
      moneyTotalApi().then(res => {
        if (res.code == 0) {
          this.chatMoney = res.data.chatMoney
          this.sportMoney = res.data.sportMoney
        }
      })
    },
    //领取(运动/社交)收益
    receiveIncome (type) {
      if (type == 0) {
        this.sportsLoading = true
      } else {
        this.socialityLoading = true
      }
      sendApi({ type }).then(res => {
        if (res.code == 0) {
          this.getMoneyTotal();
          this.page = 1
          this.loading = true
          this.finished = false
          this.list = []
          this.onLoad()
        }
        this.$toast(res.msg);
        if (type == 0) {
          this.sportsLoading = false
        } else {
          this.socialityLoading = false
        }
      })
    },
    /* tabs切换 */
    tabsToggle (val, index) {
      if (this.tabsCurrent !== index) {
        if (!this.loading) {
          this.tabsCurrent = index
          this.title = val.name
          this.page = 1
          this.loading = true
          this.finished = false
          this.list = []
          // this.startDate = ''
          // this.endDate = ''
          this.searchValue = ''
          this.onLoad()
        }
      }
    },
    timeConfirm () {
      if (!this.loading) {
        this.page = 1
        this.loading = true
        this.finished = false
        this.list = []
        this.searchValue = ''
        this.onLoad()
      }
    },
    /* 时间格式化 */
    formatDate (date) {
      return `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()}`;
    },
    /* 开始时间 */
    onStartTime (date) {
      if (this.endTime) {
        if (this.endTime < date.getTime()) {
          this.$toast(this.$t('开始时间要小于结束时间'));
          return;
        }
      }
      this.startTime = date.getTime()
      this.startDate = this.formatDate(date);
      this.startTimeShow = false;
    },
    /* 结束时间 */
    onEndTime (date) {
      // if(this.startTime){
      //   if(this.startTime <= date.getTime()){
      //     this.endTime = date.getTime()
      //     this.endDate = this.formatDate(date);
      //     this.endTimeShow = false;
      //   }else{
      //     this.$toast('结束时间要大于开始时间');
      //   }
      // }else{
      //   this.endTime = date.getTime()
      //   this.endDate = this.formatDate(date);
      //   this.endTimeShow = false;
      // }
      if (this.startTime) {
        if (this.startTime > date.getTime()) {
          this.$toast(this.$t('结束时间要大于开始时间'));
          return;
        }
      }
      this.endTime = date.getTime()
      this.endDate = this.formatDate(date);
      this.endTimeShow = false;
    },
    /* 请求列表 */
    onLoad () {
      // 异步更新数据
      getUpgradeList({
        type: this.tabsCurrent == 2 ? 4 : this.tabsCurrent + 1,
        start: this.startDate,
        end: this.endDate,
        page: this.page,
        limit: this.limit,
      }).then(res => {
        if (res.code == 0) {
          this.list = this.list.concat(res.data)
          this.loading = false;
          if (res.data.length < this.limit) {
            this.finished = true;
          }
          this.page++
        } else {
          this.finished = true;
        }
      })
    },
    //链接钱包
    waitForGlobal: function () {
      window.clearTimeout(this.timer);
      // 1. 刷新页面时是否已经连接钱包登录
      if (localStorage.getItem('Token') && this.web3 && localStorage.getItem('defaultAddress')) {
        window.clearTimeout(this.timer);
        this.tabsCurrent = 0
        this.title = this.$t('我的收益报告') + ' ：'
        this.page = 1
        this.loading = true
        this.finished = false
        this.list = []
        this.startDate = ''
        this.endDate = ''
        this.searchValue = ''
        this.onLoad();
        this.getMoneyTotal();
        this.getUpgradeNft();
        this.getUserInfo()
        this.getTeamAward();
        //    this.web3.eth.getBlockNumber((error, result) => {
        //      console.log(error, result, '--------------------')
        //    })
      } else {
        // 如果检测到没有连接钱包登录
        this.timer = setTimeout(
          this.waitForGlobal
          , 100);
      }
    },
  },
}
   </script>
   <style lang="less" scoped>
.market {
  width: 100%;
  min-height: 100vh;
  padding-top: 75px;
  padding-bottom: 60px;
  background-image: url(~@/assets/bracelet_img/8.png);
  background-repeat: repeat-y;
  background-size: 100% 100%;
  color: #ffffff;

  .report-tabs {
    margin: 19px 0 16px 0;
    background-color: rgba(43, 43, 43, 0.5);
    width: 100%;
    padding: 11px 0;
    display: flex;
    justify-content: space-around;
    align-items: center;
    .report-tabs-list {
      font-family: REEJI-PinboGB-Flash;
      font-size: 22px;
      font-weight: normal;
      font-stretch: normal;
      letter-spacing: 0px;
      color: #5c65cb;
      cursor: pointer;
    }
    .report-tabs-currentList {
      color: #a7f264;
    }
  }
  .report-filter {
    .report-filter-search {
      width: 92%;
      background-color: #ffffff;
      border-radius: 5px;
      margin: 0 auto;
      color: #2b2b2b;
      display: flex;
      align-items: center;
      padding: 10px;
      .report-search-ipt {
        flex: 1;
        height: 100%;
        input {
          width: 95%;
          height: 100%;
          font-size: 25px;
          border: 0;
        }
      }
      .report-search-img {
        width: 26px;
        height: 26px;
        img {
          width: 100%;
          height: 100%;
        }
      }
    }

    .report-filter-time {
      // width: 100%;
      background-color: rgba(43, 43, 43, 0.5);
      border-radius: 19px 0px 0px 19px;
      margin-top: 4px;
      margin-left: 2%;
      padding: 4px;
      display: flex;
      align-items: center;

      .report-time-img {
        width: 31px;
        min-width: 31px;
        height: 31px;
        border-radius: 100%;
        overflow: hidden;
        cursor: pointer;
        img {
          width: 100%;
          height: 100%;
        }
      }
      .report-time-choose {
        width: 100%;
        margin-left: 1%;
        display: flex;
        justify-content: space-around;
        align-items: center;

        .report-choose-start {
          width: 138px;
          padding: 5px 8px 3px 20px;
          background-color: #ffffff;
          border-radius: 12px;
          overflow: hidden;
          display: flex;
          align-items: center;
          cursor: pointer;
          img {
            width: 15px;
            height: 15px;
            margin-bottom: 2px;
          }
        }
        .report-choose-zhi {
          font-family: REEJI-PinboGB-Flash;
          font-size: 15px;
          font-weight: normal;
          font-stretch: normal;
          letter-spacing: 0px;
          color: #ffffff;
          // margin: 0 12px;
        }
      }
    }
    .report-myreport {
      width: 92%;
      margin: 18px auto 5px auto;
      .report-myreport-title {
        font-weight: normal;
        font-stretch: normal;
        letter-spacing: 0px;
        color: #ffffff;
        font-family: REEJI-PinboGB-Flash;
        font-size: 24px;
      }
      .profit {
        font-family: REEJI-PinboGB-Flash;
        display: flex;
        align-items: center;
        justify-content: space-between;
        font-size: 14px;
        margin-bottom: 5px;
        .btn {
          height: 25px;
          line-height: 25px;
          background: #a7f264;
          color: #000;
          border: none;
        }
      }
      .report-myreport-list {
        height: 45vh;
        background-color: #ffffff;
        border-radius: 5px;
        overflow: auto;
        padding: 0 10px;

        .report-list-border {
          border-bottom: solid 1px #6111a8;
        }
        .report-list-box {
          display: flex;

          .report-box-left {
            flex: 1;

            .report-left-title {
              font-family: AeroMaticsDisplayItalic;
              font-size: 12px;
              font-weight: normal;
              font-stretch: normal;
              letter-spacing: 0px;
              color: #2b2b2b;
            }
            .report-left-text {
              margin-top: 10px;
              .report-text-1 {
                font-family: AeroMaticsDisplayItalic;
                font-size: 7px;
                font-weight: normal;
                letter-spacing: 0px;
                color: #2b2b2b;
              }
            }
          }
          .report-box-right {
            flex: 1;
            text-align: center;
            font-family: AeroMaticsDisplayItalic;
            font-size: 12px;
            font-weight: normal;
            font-stretch: normal;
            letter-spacing: 0px;
            color: #2b2b2b;
          }
        }

        /deep/ .van-list {
          height: 100%;
        }
        /deep/ .van-cell {
          padding: 13px 4px;
          line-height: 1;
        }
        /deep/ .van-cell:nth-child(1) {
          padding-top: 8px;
        }

        /deep/ .van-cell::after {
          border: 0;
        }
      }
      .report-myreport-list::-webkit-scrollbar {
        width: 5px;
        border-radius: 5px;
        background: #fff;
      }
      .report-myreport-list::-webkit-scrollbar-track {
        border-radius: 5px;
        background: #fff;
      }
      .report-myreport-list::-webkit-scrollbar-thumb {
        border-radius: 5px;
        background: #5c65cc;
      }
    }
  }
}
/deep/ .van-cell__value--alone {
  font-family: 'AeroMaticsDisplayItalic';
  font-size: 13px;
  font-weight: normal;
  font-stretch: normal;
  letter-spacing: 0px;
  color: #2b2b2b;
}
/deep/ .van-cell {
  padding: 0;
}
</style>